import { Link, useLocation } from "react-router-dom";
const Footer = () => {
  const today = new Date();
  const location = useLocation().pathname;
  return (
    <footer className="Footer">
      <div className="buttonContainer">
        {location !== "/" && (
          <>
            <Link to="/">
              <p>Tours</p>{" "}
            </Link>
            <br />
          </>
        )}
        {location == "/" && (
          <>
            <a href="#Home">
              <p>Tours </p>
            </a>
            <br />
          </>
        )}
        {location == "/" && (
          <>
            <Link to="/about">
              <p>About</p>
            </Link>
            <br />
          </>
        )}
       
        {location == "/" && (
          <>
            <a href="#Contact">
              <p>Contact</p>{" "}
            </a>
            <br />
          </>
        )}
      </div>
      <p>Copyright &copy; {today.getFullYear()}</p>
    </footer>
  );
};

export default Footer;
