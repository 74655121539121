import { Link } from "react-router-dom";
const About = () => {
  return (

    <>
   
    <section className="About" id="About">
      <div className="mainPanel">
        <h3 className="h3Price">About</h3>
        <br />

        <div className="textContainer">
          <h3>Who we are?</h3>
          <p>
            AdvenTour is a Micro Tour Operator, based in Quepos, Puntarenas,
            Costa Rica. Focused on ecological, educational and recreational
            tourism, also providing transportation services.
            More than 12 years of experience offering activities responsibly to
            worldwide and local travelers.
          </p>
          <br/>
          <h3>Mission</h3>

          <p>
            Our mission is to consolidate as a local company who offers the
            opportunity to discover our country in a safe, aware and sustainable
            way. Incentivizing our eco-friendly and sustainable country model,
            in such a way that visitors can turn their vacations into memorable
            experiences like no other.
          </p>
          <br/>
          <h3>Vision</h3>

          <p>
            See us as a great option for travelers who want to enjoy their
            vacations, seeding new costumers by their recommendations and repeat
            as they remember the experience. Growing parallel to the local
            economy and incentivize the competition for better-quality services
            towards the tourist.
          </p>
        </div>

        <h3 className="h3Price">Staff</h3>

        <div className="textContainer">
          <p>
            <b>Berny Retana</b> is a Nature Tour Guide formed by the Instituto
            Nacional de Aprendizaje (INA), in technical careers aimed for
            tourism. With more than 12 years of experience, he got the required knowledge to announce important and cultural
            tourist places of interest in Costa Rica.
            <br />
            Native from the northern side of the country, he moved to the Costa
            Rican Pacific to complete his formation as a tour guide, enrolled at
            academic institutes provided by the Government to foment
            entrepreneurship. He opted to stablish a tour company based in
            Quepos, Puntarenas, with the main idea to diversify activities for
            local and international travelers.
            <br />
            Berny Retana has Tour Guide License <b>(N° 195)</b> extended by the
            Instituto Costarricense de Turismo (ICT).
          </p>

          <br />
          <br />

          <p>
            <b>Michelle Salazar</b> has more than 4 years of experience
            attending and guiding tourists, also in customer service. Graduated
            as a Tour Guide from the Instituto Nacional de Aprendizaje (INA).
            She is From Perez Zeledón and decided to move to Central Pacific
            with the idea of start guiding ecologic tourism and gathering
            knowledge obtained from La Amistad Pacifico Conservation Area.
            <br />
            Michelle is also an outstanding painter, she projects her passion
            for animals within her realistic oil and acrylic paintings, made
            carefully with time, love and motivation as a great artist.
            <br />
            Michelle has her Tour Guide License  <b>(N° 1734)</b> extended by the
            Instituto Costarricense de Turismo (ICT).
          </p>

          <br />
        </div>
        <h3 className="h3Price">Company Structure</h3>
        <div className="textContainer">
          <br />
          <p>
            The company has among the assets, top end optical equipment for
            bird sighting, offering unmatched quality to our customers
            to bring home a wonderful experience of our activities.
    
            We also own a modern van with capacity for 15 passengers, with all the comfort required
            to ensure our clients an extremely pleasant long-distance trips, to provide a memorable service.
          <br/>
            Our company uses procedures for civil protection and occupational risk
            policies, in addition to policies for land transportation of
            tourists, offering responsible tourism and taking care of everyone of our travelers.
     
            Our operational structure is based on the use of the region's own
            resources, such as suppliers and labor, all with their respective
            responsibilities up to date. We choose to give the possibility of
            growth to people involved in the same sustainable tourism activity.
          </p>

          <br />
        </div>
        <h3 className="h3Price">Offer</h3>
        <div className="textContainer">
          <br />
          <p>
            Our service is always focused on showing the natural resources of
            our country in a sustainable and balanced way with the environment.
            <br />
            Our objective is to show the least developed tourist attractions of
            our country as well as the important points of tourist interest in
            Costa Rica.
            <br />
            Show to the visitor the true essence of Costa Rica from within and
            improve the traveler's experience in order to maximize their
            vacations in places that are little exploited by tourists.
          </p>

          <br />
        </div>
  



      <h3 className="h3Price">Terms and Conditions</h3>
      <br />
      <br />
      <div className="textContainer">
        <br />
        <h3>Refund Policy</h3>
        <p>
          Our clients will get full refund by canceling 8 days in advance.
          <br />
          Only 50% will be refund by canceling 7 to 1 days in advance.
          <br />
          Canceling 24 hours or less, NO refundable.
        </p>
 <br />
<br/>
        <p> 
          <b>Holidays
          </b> <i> *(Easter, Thanksgiving, Christmas and New Years Eve)</i>
          </p>
        <small>
       
         
        </small>

       
        <p>
          Full refund by canceling 15 or more days in advance.
          <br />
          Canceling 14 days or less NO refundable.
        </p>
      </div>      
    </div>
    </section>
    </>
  );
};

export default About;
