import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const Tour = ({ tour }) => {
  const { auth } = useAuth();
  return (
    <>
      {tour && tour.active === "true" && (
        <>
          <article className="tour">
            <Link to={`/tour/${tour._id}`}>
              <div className="tourInfo">
                <h3>{tour.title}</h3>
                <div className="textContainer">
                  <p>
                    {tour.description.length <= 100
                      ? tour.description
                      : `${tour.description.slice(0, 90)}...`}
                  </p>
                </div>

                <h3 className="h3Price">From ${tour.unitPrice}</h3>
              </div>
              <div className="tourImage">
                <img src={tour.imageUrl} alt="img" loading="lazy" />
              </div>
            </Link>
          </article>
        </>
      )}
      {auth.username && tour && tour.active === "false" && (
        <>
          <br />
          <article className="tour">
            <Link to={`/tour/${tour._id}`}>
              <div className="tourInfo">
                <h2 className="Inactive">Inactive Tour: {tour.title}</h2>
                <br />
                <div className="textContainer">
                  <p>
                    {tour.description.length <= 150
                      ? tour.description
                      : `${tour.description.slice(0, 140)}...`}
                  </p>
                </div>

                <h3 className="h3Price">From ${tour.unitPrice}</h3>
              </div>
              <div className="tourImage">
                <img src={tour.imageUrl} alt="img" />
              </div>
            </Link>
          </article>
        </>
      )}
      {!tour && (
        <>
          <h2>Tour not found</h2>
        </>
      )}
    </>
  );
};

export default Tour;
