import axios from "../api/axios";
import useAxiosFetch from "../hooks/useAxiosFetch";
import useAuth from "../hooks/useAuth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FeedImage from "../components/FeedImage";

const UploadImage = () => {
  const dataUrl = `${process.env.REACT_APP_SERVER}/images`;
  const [images, setImages] = useState([]);
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { data } = useAxiosFetch(dataUrl);  
  const [newImage, setNewImage] = useState({
    filename: "",
    base64: ""
  });
  
  const clear = () => {
    document.getElementById('image').value=null;
  };

  const onFileChange = async (e) =>{
    e.preventDefault();
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    console.log("name: ", file.name, "base64: ", base64);
    setNewImage({
      filename: file.name,
      base64: base64,
    })

  }

  const convertBase64 =(file) =>{
    return new Promise((resolve, reject )=>
      {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result)
        };
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const onFileUpload = async (e) => {
    e.preventDefault();
    try {

      const response = await axios.post("/images", JSON.stringify(newImage), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        withCredentials: true,
      });
      console.log(response);
      alert("Image Uploaded Successfully")

     
    } catch (err) {
      console.log(`Error occurred while new art creation: ${err.message}`);
    }
  };



  useEffect(()=>{
    setImages(data);
  },[data]);

  return (
    <>

    <section className="NewTour">
      <div className="mainPanel">
        <h3 className="h3Price">Upload Image</h3>
        <form className="newTourForm" encType="multipart/form-data" onSubmit={onFileUpload} >
          <div className="buttonContainer">
            <label className="hiddenLabel" htmlFor="title">
              Image
            </label>
            <input
              id="image"
              name="image"
              type="file"
              accept=".jpeg, .png, .jpg"
              required
              onChange={onFileChange}
     
            />
           
                
          </div>

          <div className="buttonContainer">
            <button className="saveButton" type="submit">
              Upload
            </button>

            <button onClick={clear} className="backButton" type="button">
              Cancel{" "}
            </button>
          </div>
        </form>
        
    {images && (
   <>
  
        <br />
        <div className="cardContainer" >

        {(images.length ? (
          
       <FeedImage images={images} />
      ) : (
        <>       
         <h2 className="h2Home" >No images to show yet</h2>
    </>
    
      ))}
  
        </div>
    </>
    )}
      </div>


   
       </section>
    </>
  );
};

export default UploadImage;
