import FeedTour from "../components/FeedTour";

import Contact from "./Contact";

//import SearchBar from "../components/SearchBar";

const Home = ({ tours, fetchError, isLoading, /*search, setSearch */}) => {
  return (
    <>
    <section className="Home" id="Home">
    

       <br />
     {/* <SearchBar search={search} setSearch={setSearch} />*/ }
      <div className="tourContainer">
        {isLoading && <h3 className="h3Price">Loading tours...</h3>}
      {!isLoading && fetchError && <h3 className="h3Price">{fetchError}</h3>}
      {!isLoading &&
        !fetchError &&
        (tours.length ? (
          <FeedTour tours={tours} />
        ) : (
          <>
          <h3 className="h3Price">No tours to display</h3>
          </>

        ))}
      </div>

    </section>
    
    <Contact/>
    </>

  );
};

export default Home;
