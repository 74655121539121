import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import RequireAuth from "./components/RequireAuth";
import useAxiosFetch from "./hooks/useAxiosFetch";
import Layout from "./components/Layout";

import Header from "./components/Header";
import Nav from "./components/Nav";
import Footer from "./components/Footer";

import Acknowledgement from "./pages/Acknowledgement";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Login from "./pages/Login";
import EditUser from "./pages/EditUser";
import Home from "./pages/Home";
import ArtGallery from "./pages/ArtGallery";
import Dashboard from "./pages/Dashboard";
import NewTour from "./pages/NewTour";
import NewArt from "./pages/NewArt";
import EditTour from "./pages/EditTour";
import TourPage from "./pages/TourPage";
import Booking from "./pages/Booking";
import Reservations from "./pages/Reservations";
import UploadImage from "./pages/UploadImage";

import Missing from "./pages/Missing";
import Unauthorized from "./pages/Unauthorized";

function App() {
  const [tours, setTours] = useState([]);
  const [search, setSearch] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const toursDataUrl = `${process.env.REACT_APP_SERVER}/tours`;


  const { data, fetchError, isLoading, refetchData } =
    useAxiosFetch(toursDataUrl);

  useEffect(() => {
    setTours(data);
  }, [data]);

  useEffect(() => {
    try {
      if (tours) {
        const filterResults = Object.values(tours).filter(
          (tour) =>
            tour?.title?.toLowerCase().includes(search.toLowerCase()) ||
            tour?.unitPrice?.includes(search) ||
            tour?.description?.toLowerCase().includes(search.toLowerCase())
        );
        setSearchResults(filterResults);
      } else {
        console.log("Loading");
      }
    } catch (err) {
      console.error(err);
    }
  }, [tours, search]);

  return (
    <div className="App">
      <Header />
      <Nav />
      <Routes>
        <Route path="/" element={<Layout />}>
          {/*Public access*/}
          <Route exact path="/" element={
              <Home
                fetchError={fetchError}
                isLoading={isLoading}
                tours={searchResults}
                search={search}
                setSearch={setSearch}
              />
            }
          />
          <Route exact path="/tour/:id" element={<TourPage tours={tours} />} />
          <Route path="/booking/:id" element={<Booking tours={tours} />} />
          <Route exact path="/auth" element={<Login />} />
          <Route exact path="/art_gallery" element={<ArtGallery />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/about" element={<About />} />
          <Route path="/acknowledgement" element={<Acknowledgement />} />
          <Route path="/contact" element={<Contact />} />

          {/*Admin access only*/}
          <Route element={<RequireAuth />}>
            <Route exact path="/dashboard" element={<Dashboard  tours={tours} />} />
            <Route exact path="/reservations" element={<Reservations />} />
            <Route exact path="/user" element={<EditUser />} />
            <Route exact path="/images" element={<UploadImage />} />
            <Route exact path="/art/new" element={<NewArt />}/>
            <Route exact path="/tour/new"
              element={<NewTour tours={tours} setTours={setTours} />}
            />
            <Route path="/edit/:id" element={
                <EditTour
                  tours={tours}
                  setTours={setTours}
                  dataUrl={toursDataUrl}
                  refetchData={refetchData}
                />
              }
            />
          </Route>
          {/*Catch anything else*/}
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
