import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import axios from "../api/axios";

const Image = ({ image }) => {

  const { auth } = useAuth();
  const getImageId = (id) =>{
//onClick={()=> getImageId(image._id)}
    console.log(id)
  }


  const handleDelete = async ( id) => {

    try {
      const response = await axios.delete(`/images/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        withCredentials: true,
      });
    console.log("Deleted image id: ", id);
    console.log(response);
    alert("Image Deleted Successfully")
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };


  return (
    <>
      {image && (
        <>
          <article className="tour">
            <div className="tourImage" > 
              <img src={image.base64} alt="img" loading="lazy" />
             
            </div>{" "} <input
                      type="button"
                      className="del-btn"
                      onClick={() => handleDelete(image._id)}
                      value={"Delete"}
                    />
          </article>
        </>
      )}
      {!image && (
        <>
          <h2>Image not found</h2>
        </>
      )}
    </>
  );
};

export default Image;
