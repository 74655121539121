import Image from "./Image"

const FeedImage = ({ images }) => {
  return (
    <>
    {images.map(image => (
      <Image key={image._id} image={image}/>
    ))}
    </>

  )
}

export default FeedImage